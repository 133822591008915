@import "../../assets/styles/colors";

.modal {
  border-radius: 4px!important;
  max-height: 100%!important;
  max-width: 100%!important;
  min-height: 200px!important;
  overflow: visible!important;
  overflow-y: auto!important;
  position: relative!important;
  display:initial!important;

  .closeIcon {
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 10px;
    color: $primary;
  }

  &:not(.fullscreen) {
    height: fit-content;
    // max-height: 700px;
  }

  &.small {
    width: 550px;
  }

  &.medium {
    width: 900px;
  }

  &.large {
    width: 1200px;
  }

  &.fullscreen {
    height: auto;
    width: 100%;
    margin: 0 100px;
    min-height: 75%;
  }
}

@media only screen and (max-width: 600) {
  .modal {
    .closeIcon {
      cursor: pointer;
      height: 20px;
      position: absolute;
      right: 10px;
      top: 8px;
      width: 20px;
    }

    &.fullscreen {
      height: 100%;
      margin: 0;
    }

    &.small {
      margin: 15px;
    }

    &.medium {
      margin: 15px;
    }

    &.large {
      margin: 15px;
    }
  }
}
