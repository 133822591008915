@import "../../../assets/styles/colors";

.roleUnselected{
    padding: 3px 20px;
    border: 2px $darkPrimary solid;
    border-radius: 20px;
    font-weight: 500;
    margin: 10px 5px;
    transition: 0.3s all;
}

div.roleUnselected:hover{
    background-color: $darkPrimary;
    color: #fff;
    transition: 0.3s all;
    cursor: pointer;
}

.roleSelected{
    padding: 3px 20px;
    border: 2px $darkPrimary solid;
    background-color: $darkPrimary;
    color: #fff;
    border-radius: 20px;
    font-weight: 500;
    margin: 10px 5px;
    transition: 0.3s all;
}

div.roleSelected:hover{
    background-color: transparent;
    color: $darkPrimary;
    transition: 0.3s all;
    cursor: pointer;
}