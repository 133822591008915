@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,700;1,500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;500&display=swap");
@import "./assets/styles/colors";
body {
  margin: 0;
  font-family: "Poppins", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fafafa !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.primaryColorLink {
  color: $primary;
}

.formInput {
  min-width: 150px;
  max-width: 50%;
  background-color: $backgroundDefault;
  border-radius: 4px;

  .MuiInputBase-input {
    font-size: 14px;  
  }

  label {
    font-size: 14px;
  }
}