@import "../../assets/styles/colors";

.card-style {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 2rem;
    box-shadow: 0px 6px 13px 0px #00000005;
    background-color: #ffffff;
}

.card-text {
    display: flex;
    flex-direction: column;
    row-gap: 0.3rem;
    justify-content: center;
    align-items: center;
}