@import "../../assets/styles/colors";

.cards-container {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
}

@media (max-width:801px) {
    .cards-container {
        flex-direction: row;
        column-gap: 1rem;
    }
}