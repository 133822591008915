@import "../../assets/styles/colors";

.sidebar {
  position: sticky;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 78px;
  height: 100vh;
  top: 0;
  background: $backgroundPaper;
  transition: width 0.25s;
  border-right: 1px solid $backgroundDarkShade;
  z-index: 11;

  .logo {
    max-width: 100px;
    width: 100%;
    margin: 1em auto;
    padding: 0 1em;
  }

  &.full {
    width: 260px;
  }

  .linksBox {
    width: 100%;
    min-height: 300px;

    .sideLink {
      transition: 0.2s ease-in-out;
      width: 100%;
      height: 60px;
      padding: 10px 25px;
      color: $secondaryLight;
      text-decoration: none;
      text-align: center;
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;
      position: relative;
      white-space: nowrap;

      &:hover {
        color: $primary;
        transition: 0.2s ease-in-out;
      }

      p {
        width: calc(100% - 40px);
        text-align: left;
        margin-left: 30px;
      }

      svg {
        overflow: visible;
      }

      &.active {
        background: $primary;
        color: $white;
      }
    }
  }
}

.roleDisplay {
  margin: 10px 0;
  background: $primary;
  color: $white;
  font-size: 12px;
  padding: 3px 5px;
  text-transform: uppercase;
  border-radius: 5px;
}
