@import "../../assets/styles/colors";


.btn-primary {
    background-color: $primary !important;
    border-color: $primary !important;
    transition: 0.3s all;
}

.btn-primary:hover {
    background-color: $darkPrimary !important;
    border-color: $darkPrimary !important;
    transition: 0.3s all;
}

.btn-secondary {
    background-color: $secondary !important;
    border-color: $secondary !important;
    transition: 0.3s all;
}

.btn-secondary:hover {
    background-color: lighten($color: $secondary, $amount: 7) !important;
    border-color: lighten($color: $secondary, $amount: 7) !important;
    transition: 0.3s all;
}

.bg-custom-gradient {
    background-color: $backgroundDefault;
    width: 100%;
    height: 100%;
    background-position: center center;
    background-size: cover;
    overflow: hidden;
}

.login-container {
    background: $backgroundPaper;
    height: 100%;
    padding: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
}

.img-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    // z-index: -1;
}