@import "../../assets/styles/colors";

.textPrimary {
  margin-right: 20px!important;
}

button.goBackBtn {
  border: 1px $primary solid;
  border-radius: 2px!important;
  background-color: $primary ;
  color: #fff;
  box-shadow: 0 10px 20px 0 rgba(0,0,0,0.17)!important;
  transition: 0.3s all;
}

button.goBackBtn:hover{
  border: 1px $darkPrimary solid;
  background-color: $darkPrimary ;
  border-radius: 10px!important;
  transition: 0.3s all;
}


@media screen and (max-width: 768px){
  .textPrimary {
    margin: 0;
  }
}