@import "../../assets/styles/colors";

.image-dropzone-container {
  background: #E7E6E6;
  border: 2px dashed #cbcbcb;
  cursor: pointer;
  
  p {
    padding: 0;
    margin: 0;
    text-align: center;
    color: #A0A0A0;
  }
}

.thumbs-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 16px;
}

.thumb {
  display: inline-flex;
}

.thumb-inner {
  min-width: 0;
  overflow: hidden;

  position: relative;
  display: inline-block;

  img {
    display: inline-flex;
    border-radius: 2px;
    border: 1px solid #eaeaea;
    margin-bottom: 8px;
    margin-right: 8px;
    width: 150px;
    height: 150px;
    padding: 4px;
    box-sizing: border-box;
  }

  button {
    position: absolute;
    top: 0;
    right: 0;
    background: $secondary;
    color: #fff;
    margin-top: 15px;
    margin-right: 20px;
    width: 20px;
    height: 20px;
    padding: 0;
    border-radius: 2px;

    &:hover {
      background: $darkPrimary;
    }
  }
}